import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

let config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    bucketUrl: process.env.REACT_APP_FIREBASE_BUCKET_URL
};

firebase.initializeApp(config);
//initialize firestore
firebase.firestore();
// export const storage = firebaseStrc.storage();
//is needed for firebaseui cdn import
declare global {
    interface Window { firebase: any; }
}
window.firebase = firebase;

export const firestore = firebase.firestore;
export default firebase;