import React, {Component} from 'react';
import {WithStyles, withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {styles} from 'jss/Layout/Header';
import {RouteComponentProps} from "react-router-dom";
import Logo from '../../Components/Logo';
import {compose} from "redux";
import Menu from "Layout/Header/Menu";

export interface IHeaderProps extends RouteComponentProps, WithStyles<typeof styles> {
    scrollHeight?: number;
}

export class Header extends Component<IHeaderProps> {
    static defaultProps = {scrollHeight: 200}; // scroll height to show header

    componentDidMount() {
        window.addEventListener("scroll", this.headerColorChange);
        this.headerColorChange();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname)
            this.headerColorChange();
    }

    isHomePage() {
        const regEx = /^(\/teacher\/fredyclan\/lesson\/timba\/?$)|(\/live)|(\/$)|(\/for-teachers)/gm;
        return this.props.location.pathname.match(regEx) && !this.props.location.pathname.match(/(\/pay\/live)/);
    }

    headerColorChange = () => {
        const {classes, scrollHeight = -1} = this.props;
        const windowsScrollTop = window.pageYOffset;

        const header = document.body
            .getElementsByTagName("header")[0];

        if (this.isHomePage())
            if (windowsScrollTop > scrollHeight) {
                header
                    .classList.remove(classes.transparent);
            } else {
                header
                    .classList.add(classes.transparent);
            }
        else {
            header
                .classList.remove(classes.transparent);
        }
    };

    componentWillUnmount() {
        window.removeEventListener("scroll", this.headerColorChange);
    }

    render() {
        const {classes} = this.props;

        return <AppBar className={classes.header}>
            <Toolbar>
                <Logo/>

                <Menu/>
            </Toolbar>
        </AppBar>
    }
}

export default compose(
    withStyles(styles)
)(Header) as React.ComponentType;